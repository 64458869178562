export enum AdmissionDocumentsEnums {
  AdmissionServiceArgeement = 'AdmissionServiceArgeement',
  FaceSheet = 'FaceSheet',
  FaceSheet2021 = 'FaceSheet2021',
  PatientEmergencyAndContactInfo = 'PatientEmergencyAndContactInfo',
  EmployeeBackgroundCheckResults = 'EmployeeBackgroundCheckResults',
  HomeSafetyAssessment = 'HomeSafetyAssessment',
  ReliaseOfInformationForm = 'ReliaseOfInformationForm',
  DirectCareWorkerStatusPolicy = 'DirectCareWorkerStatusPolicy',
  SignatureVerificationAndConfirmation = 'SignatureVerificationAndConfirmation',
  // MedicationProfile = 'MedicationProfile',
  // AcknowlegementOfNoticeOfTheMedicationProfile = 'AcknowlegementOfNoticeOfTheMedicationProfile',
  PersonalCareHhaCarePlan = 'PersonalCareHhaCarePlan',
  PersonalCareHhaCarePlan2021 = 'PersonalCareHhaCarePlan2021',
  HomemakerSupervisoryReport = 'HomemakerSupervisoryReport',
  AcknowlegementOfNoticeOfProhibition = 'AcknowlegementOfNoticeOfProhibition',
  AcknowlegementOfChapter = 'AcknowlegementOfChapter',
  AcknowlegementOfChapterV2 = 'AcknowlegementOfChapterV2',
  ConsumerServiceAgreement = 'ConsumerServiceAgreement',
  DischargeTransferSummaryForm = 'DischargeTransferSummaryForm',
  VerificationOfParticipantsRecordReview = 'VerificationOfParticipantsRecordReview',
  HealthShareExchangePrivacyForm = 'HealthShareExchangePrivacyForm',
}

export const EBC_DOCS_NAME = 'Employee background check results - employer and consumer agreement';
